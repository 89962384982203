import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsPOSTApi, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
import PicturesUpload from "./MediaArrayUpload";
import { useSelector } from 'react-redux';
import ImageUpload from "../../ImageUpload";

export default function ImageUploadMain(props) {

  const history = useHistory()
  const [ID, setID] = React.useState("1124")


  const [reload, setReload] = React.useState(false)
  const [ImageArray, setImageArray] = React.useState([])
  const [ImageArrayReload, setImageArrayReload] = React.useState("")
  const [whatsappGroup, setWhatsappGroup] = React.useState("")
  const [LiveLink, setLiveLink] = React.useState("")
  const [videoLink, setvideoLink] = React.useState("")
  const [ImageArrayVerified, setImageArrayVerified] = React.useState([])
  const [ImagePackage, setImagePackage] = React.useState("")
  const [ImageArrayVerifiedReload, setImageArrayVerifiedReload] = React.useState("")

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const LoginUser = useSelector((state) => state.LoginState);
  const EditInfo = () => {
    setReload(true)
    const data = {
      "meta": {
        "picture_url_list": JSON.stringify(ImageArray),
        "picture_verified_list": JSON.stringify(ImageArrayVerified),
        "packages_verified_list": ImagePackage,
        "whatsapp_group": whatsappGroup,
        "live_link": LiveLink,
        "video_link": videoLink
      }
    }
    AdsPUTApi(data, "image_home", ID)
      .then((res) => {
        setReload(false)
        setAlertShow(true)
      }).catch(err => {
        console.log(err)
        console.warn(err.response.data)
        console.log("Response Status: hello11", err.response.status);
        console.log("Response Headers:", err.response.headers);
        setReload(false)
        setAlertShow(true)
        setAlertcomp(err.response.data.message)
      })
  }
  useEffect(() => {
    fetchSliders()
  }, [])
  const fetchSliders = () => {
    setImageArrayReload(false)
    AdsGETApi({ _fields: "meta" }, "image_home")
      .then((res) => {
        if (res.data.length !== 0) {
          setvideoLink(res.data[0].meta.video_link)
          setLiveLink(res.data[0].meta.live_link)
          setWhatsappGroup(res.data[0].meta.whatsapp_group)
          if (res.data[0].meta.picture_url_list.length !== 0)
            setImageArray(JSON.parse(res.data[0].meta.picture_url_list))
          // if (res.data[0].meta.picture_verified_list.length !== 0)
          setImageArrayVerified(JSON.parse(res.data[0].meta.picture_verified_list))
          setImagePackage(res.data[0].meta.packages_verified_list)
        }
        setImageArrayReload(true)
        setImageArrayVerifiedReload(true)
      })
  }

  return (
    <div>
      <div>
        <div className="row" style={{ padding: "30px 30px" }}>
          <div className="col-md-8 button">
            <h2>Orientations </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-2 col-1"></div>
          <div className="col-sm-8 col-10 form-view mb-3">
            <h4><center>Details</center></h4>
            <table>
              <tbody>
                <tr>
                  <td className="col-3">Whatsapp Link</td>
                  <td className="col-6">
                    <input className="input-common"
                      value={whatsappGroup} placeholder="Whatsapp Group Link"
                      onChange={(event) => { setWhatsappGroup(event.target.value) }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-3">Live Link</td>
                  <td className="col-6">
                    <input className="input-common"
                      value={LiveLink} placeholder="Live Link"
                      onChange={(event) => { setLiveLink(event.target.value) }}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-3">Video Link</td>
                  <td className="col-6">
                    <input className="input-common"
                      value={videoLink} placeholder="Video Link"
                      onChange={(event) => { setvideoLink(event.target.value) }}
                    />
                  </td>
                </tr>
                {LoginUser.userRole == "author" &&
                  <>
                    <tr>
                      <td className="col-3">Image For New</td>
                      <td className="col-6">
                        <PicturesUpload
                          ImageArray={ImageArray}
                          setImageArray={setImageArray}
                          ImageArrayReload={ImageArrayReload}
                          setImageArrayReload={setImageArrayReload}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="col-3">Image For Verified</td>
                      <td className="col-6">
                        {/* {JSON.stringify(ImageArrayVerified)} */}
                        <PicturesUpload
                          ImageArray={ImageArrayVerified}
                          setImageArray={setImageArrayVerified}
                          ImageArrayReload={ImageArrayVerifiedReload}
                          setImageArrayReload={setImageArrayVerifiedReload}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="col-3">Image For Package</td>
                      <td className="col-6">
                        {/* {JSON.stringify(ImageArrayVerified)} */}
                        <ImageUpload
                          ImageUrl={ImagePackage}
                          setImageUrl={setImagePackage}
                        />
                      </td>
                    </tr>
                  </>
                }
              </tbody>
            </table>
            <div className="row center m-2">
              <div className=" col-3" style={{ padding: "0 10px" }}>
                <div className="sign-in-button-4"
                  onClick={() => {
                    EditInfo()
                  }}
                >
                  Update Images
                </div>
              </div>
            </div>
          </div>
        </div>

        {reload &&
          <div className="modal" style={{ padding: "50vw" }}>
            <div className="dot-pulse"></div>
          </div>
        }
      </div>
    </div >
  )

}