import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
import { useSelector } from "react-redux";
const columns = [
  {
    id: 311,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true
  },
  {
    id: 31,
    name: "Teacher's ID",
    selector: (row) => row.meta.user_name,
    sortable: true,
    reorder: true
  },
  {
    id: 312,
    name: "Today's Code",
    selector: (row) => row.meta.class_code,
    sortable: true,
    reorder: true
  },

  {
    id: 32,
    name: "Featured Exam",
    selector: (row) => row.meta.user_featured_exam,
    sortable: true,
    reorder: true
  },
  {
    id: 3,
    name: "View",
    right: true,
    cell: (row) => <Link to={`${slugs.institute_teacher_details}${row.slug}`}>View</Link>,

  }
];

export default function InstituteTeacher() {
  const [Projects1, setProjects1] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const Login = useSelector((state) => state.LoginState);

  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_user_data)
      .then((res) => {
        setProjects1(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAllProjects({
      per_page: 4999,
      "filter[meta_query][1][key]": "user_role",
      "filter[meta_query][1][value][0]": `teacher`,
      "filter[meta_query][1][compare]": "=",
      "filter[meta_query][relation]": "AND",
      "filter[meta_query][0][key]": "batch_institute_id",
      "filter[meta_query][0][value][0]": Login.userID,
      "filter[meta_query][0][compare]": "=",
    })
  }, [])

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Class Test</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Class Test
        </div>
      </div>
      {/* <a href={slugs.new_teacher}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {

          }}
        >Add New Class Test</div>
      </a> */}

      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        <DataTable
          paginationPerPage={100}
          paginationRowsPerPageOptions={[10, 50, 100, 1000]}
          columns={columns}
          data={Projects1}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          striped
          // sortIcon={<SortIcon />}
          pagination
          selectableRows
        />
      </div>
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}