import React, { useEffect } from 'react';
import { Headphone } from '../../../Constant/api';
const AnswerOptionsSelect = ({
  AnswerArray, ArrayIndex,
  Data, DataValue, DataType,
  option
}) => {
  const [isPlayingAnswer, setIsPlayingAnswer] = React.useState(false)
  return (
    <div className={
      AnswerArray[ArrayIndex].correct_answer == option ?
        "correct-option-result" :
        Data == option ?
          "wrong-option-result"
          : ""
    }>
      <div className="answer-option-style"
        style={{ display: 'flex' }}
      >

        {Data == option ?
          <i class="fa fa-check-circle-o"></i>
          : <i class="fa fa-circle-o"></i>
        }
        <div className={
          Data == option ?
            "active-option2"
            : "passive-option2"
        }>
          {DataType == "text" &&
            `${DataValue}`
          }
          {DataType == "image" &&
            <img
              src={DataValue}
              style={{
                height: "100px",
                objectFit: 'contain',
                border: "0.5px solid #000"
              }}
            />
          }

          {DataType == "audio" &&
            // <video width="400" controls>
            //   <source src={`${DataValue}`} type="video/mp4"
            //     style={{ height: "150px", width: "200px" }}
            //   />
            // </video>
            <div style={{ cursor: "pointer" }} onClick={() => {
              try {
                const sound = new Audio(DataValue)
                sound.play()
                setIsPlayingAnswer(true)
                sound.addEventListener('ended', () => {
                  setIsPlayingAnswer(false)
                })
              }
              catch {
                console.log("error")
              }
            }}>
              <div>
                <div style={{
                  position: "absolute"
                }}>
                  <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "80px", height: "80px" }}>
                    {isPlayingAnswer ?
                      <i className="fa fa-pause" style={{ fontSize: "25px" }}></i>
                      :
                      <i className="fa fa-play " style={{ fontSize: "25px" }}></i>
                    }
                  </div>
                </div>
                <img src={Headphone}
                  style={{ width: "80px", height: "80px", }}
                />
              </div>

              {/* <div className="button-play" style={{
                borderColor: mediaPlayed[`answer-${oneQuestionData.question_number}-${index2}`] == "0" ? "#000" : "#666",
                color: mediaPlayed[`answer-${oneQuestionData.question_number}-${index2}`] == "0" ? "#000" : "#666"
              }}>
                {isPlayingAnswer ?
                  <i className="fa fa-pause"></i>
                  :
                  <i className="fa fa-play "></i>
                }
              </div> */}
            </div>

          }
          {DataType == "youtube" &&
            <iframe width="400" height="150" src={`https://www.youtube.com/embed/${DataValue}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          }
        </div>
      </div>
    </div>
  )
}
export default function SecondResultScreenComponent(props) {
  const {
    oneQuestionData,
    AnswerArray, ArrayIndex,
    setQuestionsSolved, QuestionsSolved
  } = props;
  const [question, setQuestion] = React.useState(oneQuestionData.question)
  const [isPlayingAnswer, setIsPlayingAnswer] = React.useState(false)

  const [AnswerSelected, setAnswerSelected] = React.useState("")
  useEffect(() => {
    if (AnswerArray[ArrayIndex].answerChoosen !== "") {
      setAnswerSelected(AnswerArray[ArrayIndex].answerChoosen)
    }
  }, [])


  return (
    <div>
      <div className="row">
        <div className="col-md-8">
          <div style={{ margin: "3px", padding: "10px", border: "1px solid #999", minHeight: "50vh" }}>
            <div >
              <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
                {oneQuestionData.question_number}. {oneQuestionData.question}
              </h3>
            </div>
            <div className="question-description col-10">
              {oneQuestionData.question_description_type == "text" &&
                <div style={{ fontSize: "16px", border: "1px solid #000", padding: "15px" }}>
                  <div dangerouslySetInnerHTML={{ __html: oneQuestionData.question_description.replaceAll('\n', '<br/>') }}
                    style={{ fontSize: 16 }}
                  />
                  {/* {oneQuestionData.question_description} */}
                </div>
              }
              {oneQuestionData.question_description_type == "image" &&
                <img
                  src={oneQuestionData.question_description}
                  style={{
                    height: "300px",
                    objectFit: 'contain',
                    border: "0.5px solid #000"
                  }}
                />
              }
              {oneQuestionData.question_description_type == "audio" &&
                <div style={{ cursor: "pointer" }} onClick={() => {
                  try {
                    const sound = new Audio(oneQuestionData.question_description)
                    sound.play()
                    setIsPlayingAnswer(true)
                    sound.addEventListener('ended', () => {
                      setIsPlayingAnswer(false)
                    })
                  }
                  catch {
                    console.log("error")
                  }
                }}>
                  <div>
                    <div style={{
                      position: "absolute"
                    }}>
                      <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "80px", height: "80px" }}>
                        {isPlayingAnswer ?
                          <i className="fa fa-pause" style={{ fontSize: "25px" }}></i>
                          :
                          <i className="fa fa-play " style={{ fontSize: "25px" }}></i>
                        }
                      </div>
                    </div>
                    <img src={Headphone}
                      style={{ width: "80px", height: "80px", }}
                    />
                  </div>

                  {/* <div className="button-play" style={{
                    borderColor: mediaPlayed[`answer-${oneQuestionData.question_number}-${index2}`] == "0" ? "#000" : "#666",
                    color: mediaPlayed[`answer-${oneQuestionData.question_number}-${index2}`] == "0" ? "#000" : "#666"
                  }}>
                    {isPlayingAnswer ?
                      <i className="fa fa-pause"></i>
                      :
                      <i className="fa fa-play "></i>
                    }
                  </div> */}
                </div>

              }
              {oneQuestionData.question_description_type == "video" &&
                <video width="400" controls>
                  <source src={`${oneQuestionData.question_description}`} type="video/mp4"
                    style={{ height: "150px", width: "200px" }}
                  />
                </video>
              }
              {oneQuestionData.question_description_type == "youtube" &&
                <iframe width="400" height="150" src={`https://www.youtube.com/embed/${oneQuestionData.question_description}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              }
            </div>
            {oneQuestionData.question_media !== "" &&
              <div className="question-description col-10">
                {oneQuestionData.question_media_type == "text" &&
                  `${oneQuestionData.question_media}`
                }
                {oneQuestionData.question_media_type == "image" &&
                  <img
                    src={oneQuestionData.question_media}
                    style={{
                      height: "300px",
                      objectFit: 'contain',
                      border: "0.5px solid #000"
                    }}
                  />
                }

                {oneQuestionData.question_media_type == "audio" &&
                  <div style={{ cursor: "pointer" }} onClick={() => {
                    try {
                      const sound = new Audio(oneQuestionData.question_media)
                      sound.play()
                      setIsPlayingAnswer(true)
                      sound.addEventListener('ended', () => {
                        setIsPlayingAnswer(false)
                      })
                    }
                    catch {
                      console.log("error")
                    }
                  }}>
                    <div>
                      <div style={{
                        position: "absolute"
                      }}>
                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", width: "80px", height: "80px" }}>
                          {isPlayingAnswer ?
                            <i className="fa fa-pause" style={{ fontSize: "25px" }}></i>
                            :
                            <i className="fa fa-play " style={{ fontSize: "25px" }}></i>
                          }
                        </div>
                      </div>
                      <img src={Headphone}
                        style={{ width: "80px", height: "80px", }}
                      />
                    </div>

                    {/* <div className="button-play" style={{
                      borderColor: mediaPlayed[`answer-${oneQuestionData.question_number}-${index2}`] == "0" ? "#000" : "#666",
                      color: mediaPlayed[`answer-${oneQuestionData.question_number}-${index2}`] == "0" ? "#000" : "#666"
                    }}>
                      {isPlayingAnswer ?
                        <i className="fa fa-pause"></i>
                        :
                        <i className="fa fa-play "></i>
                      }
                    </div> */}
                  </div>

                }
                {oneQuestionData.question_media_type == "video" &&
                  <video width="400" controls>
                    <source src={`${oneQuestionData.question_media}`} type="video/mp4"
                      style={{ height: "150px", width: "200px" }}
                    />
                  </video>
                }
                {oneQuestionData.question_media_type == "youtube" &&
                  <iframe width="400" height="150" src={`https://www.youtube.com/embed/${oneQuestionData.question_media}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                }
              </div>
            }
          </div>
        </div>
        <div className="col-md-4">
          <div style={{ border: "1px solid #999", minHeight: "50vh" }}>
            <AnswerOptionsSelect
              AnswerArray={AnswerArray} ArrayIndex={ArrayIndex}
              DataType={oneQuestionData.answer_media_type}
              Data={AnswerSelected}
              DataValue={oneQuestionData.option_1}
              option={"option 1"}
            />
            <AnswerOptionsSelect
              AnswerArray={AnswerArray} ArrayIndex={ArrayIndex}
              DataType={oneQuestionData.answer_media_type}
              Data={AnswerSelected}
              DataValue={oneQuestionData.option_2}
              option={"option 2"}
            />
            <AnswerOptionsSelect
              AnswerArray={AnswerArray} ArrayIndex={ArrayIndex}
              DataType={oneQuestionData.answer_media_type}
              Data={AnswerSelected}
              DataValue={oneQuestionData.option_3}
              option={"option 3"}
            />
            <AnswerOptionsSelect
              AnswerArray={AnswerArray} ArrayIndex={ArrayIndex}
              DataType={oneQuestionData.answer_media_type}
              Data={AnswerSelected}
              DataValue={oneQuestionData.option_4}
              option={"option 4"}
            />
          </div>
        </div>
      </div>
      <div className="center row">
        <h3 className="col-5 ">
          {AnswerArray[ArrayIndex].solved == 0 ?
            <div className="wrong-option-result-2 ">
              Question Unsolved
            </div> :
            <div>
              {AnswerArray[ArrayIndex].correct_answer == AnswerArray[ArrayIndex].answerChoosen ?
                <div className="correct-option-result-2 ">
                  Correct Answer
                </div>
                :
                <div className="wrong-option-result-2 ">
                  Wrong Answer
                </div>
              }
            </div>
          }

        </h3>
      </div>
    </div>
  )
}
