import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";


export default function AllRegistration() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [deleteUsersModal, setDeleteUsersModal] = React.useState(false)
  const [commentModal, setCommentModel] = React.useState(false)
  const [UserID, setUserID] = React.useState("")
  const [Comment1, setComment1] = React.useState("")
  const [Comment2, setComment2] = React.useState("")
  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, "registration_data")
      .then((res) => {
        // setProjects1(res.data)
        setCustomersList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }

  useEffect(() => {
    GetAllProjects({
      per_page: 10000,
      _fields: "id,title,slug,meta",
    })
  }, [])

  const columns = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      width: "100px"
    },
    {
      id: 31,
      name: "Name",
      selector: (row) => row.meta.name,
      sortable: true,
      reorder: true,
      width: "150px"
    },
    {
      id: 32,
      name: "Phone",
      selector: (row) => row.meta.phone_number,
      sortable: true,
      reorder: true,
      width: "200px"
    },
    {
      id: 321,
      name: "Course",
      selector: (row) => row.meta.course,
      sortable: true,
      reorder: true,
      width: "150px"
    },

    {
      id: 331,
      name: "Comment",
      selector: (row) =>
        <div>
          <div style={{cursor:"pointer"}}
            onClick={() => {
              setCommentModel(true)
              setUserID(row.id)
              setComment1(row.meta.comment1)
              setComment2(row.meta.comment2)
            }}
          >Add</div>
          {row.meta.comment1 !== "" &&
            <div> 1.{row.meta.comment1}</div>
          }
          {row.meta.comment2 !== "" &&
            <div> 2.{row.meta.comment2}</div>
          }
        </div>,
      sortable: true,
      reorder: true,
      width: "300px"
    },
    {
      id: 33,
      name: "Email",
      selector: (row) => row.meta.email,
      sortable: true,
      reorder: true,
      width: "300px"
    },
  ];

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Orientation Registrations</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Registrations
        </div>
      </div>
      <div style={{ display: "inline-block", margin: "0px 5px" }}
        onClick={() => {
          setDeleteUsersModal(true)
        }}
      >
        Delete Selected
      </div>

      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        <DataTable
          columns={columns}
          data={CustomersList}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          striped
          paginationPerPage={100}
          paginationRowsPerPageOptions={[10, 50, 100, 1000]}
          pagination
          selectableRows
          onSelectedRowsChange={(r) => {
            console.log("asdasd", r)
            setProjects1(r.selectedRows)
          }}
        />
      </div>
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }

      {deleteUsersModal &&
        <section className="modal" >
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setDeleteUsersModal(false)
                  }}
                >X</div>
                Do you really want to Delete all the user?<br />
                <div className="row">
                  <div className="col-3 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setDeleteUsersModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-3 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        for (let i = 0; i < Projects1.length; i++) {
                          setReload(true)
                          setDeleteUsersModal(false)
                          AdsPUTApi({
                            status: "draft"
                          }, "registration_data", Projects1[i].id)
                            .then((res) => {
                              if (i == Projects1.length - 1) {
                                GetAllProjects({
                                  per_page: 10000,
                                  _fields: "id,title,slug,meta",
                                })
                              }
                            })
                            .catch((err) => {
                              console.log(err)
                            })
                        }
                      }}
                    >
                      Delete all All Registration
                    </div>
                  </div>
                </div>
                <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px", border: "1px solid #999" }}>
                  <DataTable
                    paginationPerPage={100}
                    paginationRowsPerPageOptions={[10, 50, 100, 1000]}
                    columns={columns}
                    data={Projects1}
                    defaultSortFieldId={1}
                    defaultSortAsc={false}
                    striped
                    // sortIcon={<SortIcon />}
                    pagination
                    selectableRows={false}

                  />
                </div>
              </div>
            </div>
          </div>
        </section>

      }

      {commentModal &&
        <section className="modal" >
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setCommentModel(false)
                  }}
                >X</div>
                <table>
                  <tbody>
                    <tr>
                      <td className="col-3">Comment 1</td>
                      <td className="col-6">
                        <input className="input-common"
                          value={Comment1} placeholder="Comment 1"
                          onChange={(event) => { setComment1(event.target.value) }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="col-3">Comment 2</td>
                      <td className="col-6">
                        <input className="input-common"
                          value={Comment2} placeholder="Comment 2"
                          onChange={(event) => { setComment2(event.target.value) }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="row center m-2">
                  <div className=" col-3" style={{ padding: "0 10px" }}>
                    <div className="sign-in-button-4"
                      onClick={() => {
                        // EditInfo()
                        AdsPUTApi(
                          {
                            meta: {
                              'comment1': Comment1,
                              'comment2': Comment2,
                            }
                          }, "registration_data",UserID)

                          .then((res) => {
                            console.log("done")
                          })
                          .catch(err => {
                            console.log(err)
                          }).finally(() => {
                            setCommentModel(false)
                            GetAllProjects({
                              per_page: 10000,
                              _fields: "id,title,slug,meta",
                            })
                          })
                      }}
                    >
                      Update Comment
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </div >
  )
}