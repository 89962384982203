import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { slugs } from "../../../Constant/slugs";
import { AdsGETApiAuth } from "../../../Constant/api";
const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.id,
    sortable: true,
    reorder: true,
    compact: true,
    padding: 0,
    width: "100px"
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true,
    compact: true,
    padding: 0,
    width: "250px"
  },
  {
    id: 32,
    name: "Live",
    selector: (row) => row.meta.class_link.includes("https://") ? "Live" : "",
    sortable: true,
    reorder: true,
    compact: true,
    padding: "10px",
    width: "100px"
  },
  {
    id: 322,
    name: "Batches",
    selector: (row) => {
      return (
        <div>
          {JSON.parse(row.meta.batches).map((item, index) => (
            <div>
              {item.name} ({item.category})
            </div>
          ))}
        </div>
      )
    },
  },
  {
    id: 3,
    name: "View",
    cell: (row) => <Link to={`${slugs.chapter_details}${row.slug}`}>View</Link>,
    compact: true,
    padding: 0,
    width: "100px"

  }
];

export default function AllChapter() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_chapters)
      .then((res) => {
        setProjects1(res.data)
        setCustomersList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAllProjects({
      per_page: 100,
      _fields: "id,title,slug,meta"
    })
  }, [])

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Classes</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Classes
        </div>
      </div>
      <a href={slugs.new_chapter}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {

          }}
        >Add New Class</div>
      </a>
      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        <DataTable
          columns={columns}
          data={CustomersList}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          striped
          // sortIcon={<SortIcon />}
          pagination
          selectableRows
        />
      </div>
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}