import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth, AdsPOSTApiWithoutAuth, AdsPUTApi, AdsPUTApiToken, ImageURL, sideBarImage, UserMeEdit } from '../../Constant/api';
import { slugs } from '../../Constant/slugs';
import { login, logout } from '../../redux/action';
import store from '../../redux/store';
import { CustomButton } from '../CustomButtom';
import ImageUpload from '../ImageUpload';
import SideBar from '../SideBar';

const AlertComponent = ({
  setAlertShow,
  Alertcomp
}) => {
  return (
    <div className="modal"
      onClick={() => {
        setAlertShow(false)
        window.location.reload(false)
      }}
    >
      <div>
        <div className="row " style={{ minHeight: "10vh", marginTop: "10vh" }}>
          <div className="col-lg-4 col-1"></div>
          <div className="col-lg-4 col-10 pb-5  bg-white  shadow">
            <div className="p-5">
              <div dangerouslySetInnerHTML={{ __html: Alertcomp }}></div>
            </div>
            <div style={{ display: 'flex', cursor: 'pointer' }} className="center">
              <div className="sign-in-button-1 col-8">
                OK
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default function Registration() {
  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [phone, setPhone] = React.useState("")
  const [Course, setCourse] = React.useState("")
  const [CourseType, setCourseType] = React.useState("")
  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const [reload, setReload] = React.useState(false)
  const [VerifiedStatus, setVerifiedStatus] = React.useState("")
  const [Loading, setLoading] = React.useState(true)
  const [Sliders, setSliders] = React.useState([])
  const [Sliders2, setSliders2] = React.useState([])
  const [whatsappGroup, setWhatsappGroup] = React.useState("")
  const [LiveLink, setLiveLink] = React.useState("")
  const [videoLink, setvideoLink] = React.useState("")
  const LoginState = useSelector((state) => state.LoginState);

  const RegisterNow = () => {
    setReload(true)
    AdsPOSTApiWithoutAuth({
      "status": "publish",
      meta: {
        "name": `${name}`,
        "phone_number": `${phone}`,
        "course": `${Course}`,
        "course_type": `${CourseType}`,
        "email": `${email}`
      }
    }, "registration_data")
      .then((res) => {
        console.log(res.data)
        // setregisterComp(false)
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    fetchSliders()
  }, [])
  const fetchSliders = () => {
    AdsGETApi({ _fields: "meta" }, "image_home")
      .then((res) => {
        console.log(res.data)
        setLoading(false)
        if (res.data.length !== 0) {
          setvideoLink(res.data[0].meta.video_link)
          setLiveLink(res.data[0].meta.live_link)
          setWhatsappGroup(res.data[0].meta.whatsapp_group)
          if (res.data[0].meta.picture_url_list.length !== 0)
            setSliders(JSON.parse(res.data[0].meta.picture_url_list))
          if (res.data[0].meta.picture_verified_list.length !== 0)
            setSliders2(JSON.parse(res.data[0].meta.picture_verified_list))
        }
      })
  }
  return (
    <div >
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <center>
            <img src={Sliders[0]} style={{ width: "60%" }} />
          </center>
          <div style={{margin: "20px",}}></div>
          <div style={{  display: "inline", overflowY: "scroll" }}>
            {/* <div className="col-sm-6">
              𝐊𝐇𝐔𝐌𝐒𝐔𝐍𝐆 𝐊𝐎𝐑𝐄𝐀𝐍 𝐁𝐇𝐀𝐒𝐇𝐀<br />
              कोरियन भाषाको लागि एक मात्र गन्तव्य।<br />
              सफल हुनु अघि पनि, सफल भए पछि पनि।<br />
              <br />
              𝑴𝒐𝒓𝒆 𝑫𝒆𝒕𝒂𝒊𝒍𝒔<br />
              𝐍𝐓𝐂 - 9851057581, 9848753238<br />
              𝐍𝐜𝐞𝐥𝐥 - 9818499716, 9818499736<br />
              𝙱𝚊𝚐𝚋𝚊𝚣𝚊𝚛 - 𝟸𝟾, 𝙺𝚃𝙼  𝙽𝚎𝚊𝚛 𝙱𝚢 𝙺𝙼𝙲<br />
              मेगा बैँकसँगैकाे पहेँलाे घरकाे Opposite<br />
            </div> */}
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>Orientation (Demo कक्षा)</div>
            {LoginState.token == null &&
              <div style={{ display: "inline-block", padding: "0 10px", marginBottom: 20 }}>
                <Link className="sign-in-button-4"
                  to="/"
                >
                  Login
                </Link>
              </div>
            }
            <div style={{ display: "inline-block", padding: "0 10px", marginBottom: 20 }}>
              <a className="sign-in-button-4"
                href={LiveLink} target="_blank"
              >
                Join Zoom Live
              </a>
            </div>
            <div style={{ display: "inline-block", padding: "0 10px", marginBottom: 20 }}>
              <a className="sign-in-button-4"
                href={whatsappGroup} target="_blank"
              >
                Whatsapp Group
              </a>
            </div>
            <div style={{ display: "inline-block", padding: "0 10px", marginBottom: 20 }}>
              <a className="sign-in-button-4"
                href={videoLink} target="_blank"
              >
                Demo Videos
              </a>
            </div>
          </div>
          <h4><center>Registration</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Name</td>
                <td className="col-6">
                  <input className="input-common"
                    value={name} placeholder="Name"
                    onChange={(event) => { setName(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">email</td>
                <td className="col-6">
                  <input className="input-common"
                    value={email} placeholder="email"
                    onChange={(event) => { setEmail(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Phone</td>
                <td className="col-6">
                  <input className="input-common"
                    value={phone} placeholder="Phone"
                    onChange={(event) => { setPhone(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Course Type</td>
                <td className="col-6">
                  <CustomButton setData={setCourseType} Data={CourseType} DataValue={"Online"} />
                  <CustomButton setData={setCourseType} Data={CourseType} DataValue={"Physical"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Course</td>
                <td className="col-6">
                  <CustomButton setData={setCourse} Data={Course} DataValue={"0 Level"} />
                  <CustomButton setData={setCourse} Data={Course} DataValue={"Grammer"} />
                  <CustomButton setData={setCourse} Data={Course} DataValue={"3 Hrs Special"} />
                  <CustomButton setData={setCourse} Data={Course} DataValue={"Advanced"} />
                  <CustomButton setData={setCourse} Data={Course} DataValue={"Exam FeedBack"} />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  if (phone !== "")
                    RegisterNow()
                }}
              >
                Register Now
              </div>
            </div>
          </div>

        </div>
      </div>
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}