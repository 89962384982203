import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { NodePOSTApiAuth } from "../../Constant/api";
import { slugs } from "../../Constant/slugs";


export default function AllNotices() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [deleteUsersModal, setDeleteUsersModal] = React.useState(false)
  const [commentModal, setCommentModel] = React.useState(false)
  const [UserID, setUserID] = React.useState("")
  const [Comment1, setComment1] = React.useState("")
  const [Comment2, setComment2] = React.useState("")
  const myState = useSelector((state) => state.LoginState);
  const [CategoriesList, setCategoriesList] = React.useState([])
  const [FetchBlogs, setFetchBlogs] = React.useState(false)
  const GetAllCategories = () => {
    NodePOSTApiAuth({
      userID: myState.userID
    }, "notification")
      .then((res) => {
        setCategoriesList(res.data.notification)
        setFetchBlogs(true)
      })
      .catch(err => {
      })
  }
  useEffect(() => {
    GetAllCategories()
  }, [])
  const DateConverter2 = (date) => {
    const dateCoverted = new Date(date)
    const month = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"]
    return `${dateCoverted.getDate()}, ${month[dateCoverted.getMonth()]} ${dateCoverted.getFullYear()} at ${dateCoverted.getHours()}: ${dateCoverted.getMinutes()}`
  }

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>Notice</h2>
        </div>
      </div>
      <div className="row">
        {CategoriesList.map((item, index) => (
          <section key={index} className="col-lg-6">
            <div className="row" style={{ padding: "10px", backgroundColor: "#d4dcff", margin: 5, border: "2px solid #999", borderRadius: 10 }}>
              <div className="col-2">
                <img src={"https://api.occoed.com/wp-content/uploads/2024/03/bell.png"}
                  style={{ width: 30 }}
                />
              </div>
              <div className="col-10">
                <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                  {item.title.rendered}
                </div>
                <div style={{ fontSize: "12px" }}>
                  {DateConverter2(item.date)}
                </div>
                <div>

                  <div style={{ fontSize: "14px", marginBottom: "20px" }} dangerouslySetInnerHTML={{ __html: item.meta.description.replaceAll('\n', '<br/>') }}></div>
                  <div style={{ display: "inline", }}>
                    {item.meta.link_outside !== null &&
                      <>
                        {item.meta.link_outside.includes("http") &&
                          <div style={{ display: "inline-block", padding: "0 10px", marginBottom: 20 }}>
                            <a className="sign-in-button-4"
                              href={item.meta.link_outside} target="_blank"
                            >
                              Open Link
                            </a>

                          </div>
                        }
                      </>

                    }
                    {item.meta.classroom != null&&item.meta.classroom != "" &&
                      <div style={{ display: "inline-block", padding: "0 10px", marginBottom: 20 }}>

                        <a className="sign-in-button-4"
                          href={slugs.classroom}
                          onClick={()=>{
                            localStorage.setItem('teacher_name',`${item.meta.teacher_id_number}`);
                            localStorage.setItem('teacher_code',`${item.meta.teacher_code}`);
                        
                          }}
                        >
                          
                          Start Test
                        </a>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))}
      </div>

    </div >
  )
}