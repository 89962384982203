import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import FileUpload from "../../../FileUpload";
import ImageUpload from "../../../ImageUpload";

export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [BatchName, setBatchName] = React.useState(FetchData.meta.batch_name)
  const [BatchStartDate, setBatchStartDate] = React.useState(FetchData.meta.batch_start_date)
  const [BatchEndDate, setBatchEndDate] = React.useState(FetchData.meta.batch_end_date)
  const [BatchStatus, setBatchStatus] = React.useState(FetchData.meta.batch_status)
  const [Chapters, setChapters] = React.useState(JSON.parse(FetchData.meta.chapters))

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const type_array = {
    "korean": "Korean",
    "nasu": "nasu",
    "sakhaadhikrit": "sakhaadhikrit",
  }
  const EditInfo = () => {
    setReload(true)
    const data = {
      "title": Title,
      "status": "publish",
      "meta": {
        "batch_name": Title,
        "batch_start_date": BatchStartDate,
        "batch_end_date": BatchEndDate,
        "batch_status": BatchStatus,
        "chapters": JSON.stringify(Chapters),
        "category_id": `${CategoryID}`,
        "category_name": CategoryName
      }
    }

    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_batch)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Batch Added Successfully")
          history.push(`${slugs.batch_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_batch, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  const [CategoryModal, setCategoryModal] = React.useState(false)
  const [CategoryID, setCategoryID] = React.useState(FetchData.meta.category_id)
  const [CategoryName, setCategoryName] = React.useState(FetchData.meta.category_name)
  const [CategoriesList, setCategoriesList] = React.useState([])
  const GetAllCategories = () => {
    setReload(true)
    setCategoriesList([])
    AdsGETApiAuth({
      per_page: 100,
      _fields: "id,title,slug,meta"
    }, slugs.db_slug_category)
      .then((res) => {
        setCategoriesList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAllCategories()
  }, [])
  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Batch Information</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Batch Name</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Batch Description</td>
                <td className="col-6">
                  <textarea rows={3} className="input-common"
                    value={BatchStartDate} placeholder="Batch Description"
                    onChange={(event) => { setBatchStartDate(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Featured Image</td>
                <td className="col-6">
                  <ImageUpload
                    ImageUrl={BatchEndDate}
                    setImageUrl={setBatchEndDate}
                  />
                </td>
              </tr>
              {/* <tr>
                <td className="col-3">Batch Start Date</td>
                <td className="col-6">
                  <input className="input-common"
                    value={BatchStartDate} placeholder="Start Date"
                    onChange={(event) => { setBatchStartDate(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Batch End Date</td>
                <td className="col-6">
                  <input className="input-common"
                    value={BatchEndDate} placeholder="Name"
                    onChange={(event) => { setBatchEndDate(event.target.value) }}
                  />
                </td>
              </tr> */}
              <tr>
                <td className="col-3">Batch Status</td>
                <td className="col-6">
                  <CustomButton setData={setBatchStatus} Data={BatchStatus} DataValue={"Running"} />
                  <CustomButton setData={setBatchStatus} Data={BatchStatus} DataValue={"Ended"} />
                </td>
              </tr>

              <>
                <tr>
                  <td className="col-3">Batch Category</td>
                  <td className="col-6"
                    onClick={() => {
                      setCategoryModal(!CategoryModal)
                    }}
                  >
                    <div className="row">
                      <div className="col-8">
                        <div className="input-common">
                          {CategoryName}&nbsp;
                        </div>
                      </div>
                      <div className="col-4"
                        onClick={() => {
                          setCategoryID("")
                          setCategoryName("")
                        }}
                      >
                        Clear
                      </div>
                    </div>
                  </td>
                </tr>
                {CategoryModal &&
                  <section className="modal" >
                    <div className="row">
                      <div className="col-md-3 col-1"></div>
                      <div className="col-md-6 col-10  shadow bg-white">
                        <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                          <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                            onClick={() => {
                              setCategoryModal(!CategoryModal)
                            }}
                          >X</div>
                          <table>
                            <tbody>
                              {CategoriesList.map((item, index) => (
                                <tr className="row bg-white shadow" key={index}
                                  style={{ cursor: "pointer", border: "1px solid #999" }}
                                  onClick={() => {
                                    setCategoryID(`${item.id}`)
                                    setCategoryName(item.title.rendered)
                                    setCategoryModal(!CategoryModal)
                                  }}
                                >
                                  <td className="col-1">
                                    {CategoryID !== item.id ?
                                      <i className="fa fa-square-o" />
                                      :
                                      <i className="fa fa-check-square-o" />
                                    }
                                  </td>
                                  <td className="col-2" >
                                    {item.id}
                                  </td>
                                  <td className="col-3">
                                    {item.title.rendered}
                                  </td>
                                  <td className="col-3">
                                    {item.meta.batch_start_date}
                                  </td>
                                  <td className="col-3">
                                    {item.meta.batch_end_date}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </section>
                }
              </>

            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                Update Batch
              </div>
            </div>
          </div>
        </div>
      </div >
      {/* {JSON.stringify(Chapters)} */}
      < div className="row" >
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Chapters List</center></h4>
          <table>
            <tbody>
              <tr>
                <td>Chapter ID</td>
                <td>Chapter Name</td>
              </tr>

              {Chapters.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.title}</td>
                </tr>
              ))}
              {/* <tr>
                    <td style={{ cursor: "pointer" }} onClick={() => {
                      setAddNew(true)
                    }}>Add New</td>
                  </tr> */}
            </tbody>
          </table>
        </div>
      </div >
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div >
  )

}