import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth, AdsPOSTApi, ExamBlockPlaceHolder } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import { logout } from '../../../redux/action';

const slug = {
  "ubt": "UBT",
  "cbt-chemical": "CBT CHEMICAL",
  "cbt-electronics-electricity": "CBT ELECTRONIC ELECTRCITY",
  "cbt-food-related": "CBT FOOD RELATED",
  "cbt-mechinery-molding": "CBT MACHINERY,MOLDING",
  "cbt-metal": "CBT METAL",
  "cbt-pulp-paper-wood": "CBT PULP PAPER WOOD",
  "cbt-rubber": "CBT RUBBER",
  "cbt-textile-sewing": "CBT TEXTILE SEWING"
}
export default function AllMyExamList() {
  const [AllExamList1, setAllExamList1] = React.useState([]);
  const [AllExamListUnavailable, setAllExamListUnavailable] = React.useState([]);
  const [reload, setReload] = React.useState(false)
  const [pagesLength, setPagesLength] = React.useState("1");
  const [pages, setPages] = React.useState(1);
  const dispatch = useDispatch()
  const [usersData, setUsersData] = React.useState({ meta: { number_of_exams: "0" } });
  const LoginState = useSelector((state) => state.LoginState);

  const fetchExam = (perPage, verifiedExamList) => {
    setReload(true)
    AdsGETApiAuth({
      ...perPage,
    }, slugs.db_slug_batch)
      .then((res) => {
        // setAllExamList(res.data)
        const examList = res.data
        var verified = [];
        var unverified = [];
        for (let i = 0; i < examList.length; i++) {
          if (verifiedExamList.filter(a => a.course_id == examList[i].id).length !== 0)
            verified.push(examList[i])
          else
            unverified.push(examList[i])
          setAllExamList1(verified)
        }
        setReload(false)
        setPagesLength(res.headers["x-wp-totalpages"])
        console.log(res.data.length)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
        if (err.message.includes("Request failed with status code 500"))
          dispatch(logout())
      })
  }
  let myarray = []
  for (let i = 0; i < pagesLength; i++)
    myarray.push(
      <div key={i} style={{ margin: "3px" }}>
        <button onClick={() => {
          setPages(i + 1)
          setAllExamList1([])
          fetchExam(setAllExamList1, { page: i + 1, per_page: 100, order: "asc" })
        }}
          style={{ border: "0", padding: "7px", backgroundColor: pages == i + 1 ? "#0d02a6" : "#f00", color: "#fff", borderRadius: "5px" }}
        >
          {i + 1}
        </button>
      </div>
    )
  const location = useLocation()
  useEffect(() => {
    // fetchExam(setAllExamList1, {
    //   per_page: 100, order: "asc",
    //   _fields: "id,title,slug,meta.available_unavailable,meta.exam_question_block_student,meta.featured_image"
    // })
    AdsGETApiAuth({
      status: "any",
      "filter[meta_query][1][key]": "user_id",
      "filter[meta_query][1][value][0]": LoginState.userID,
      "filter[meta_query][1][compare]": "=",
      _fields: "meta"
    }, "user_data")
      .then((response) => {
        if (response.data.length !== 0) {
          setUsersData(response.data[0])
          var verifiedExamListCache = response.data[0].meta.verified_course_list
          var verifiedExamList;
          if (verifiedExamListCache == "")
            verifiedExamList = [];
          else
            verifiedExamList = JSON.parse(verifiedExamListCache)
        }
        fetchExam({
          per_page: 1000,
          order: "asc",
          _fields: "id,title,meta,slug",
        },
          verifiedExamList
        )
      })
      .catch(e => {
        if (e.message.includes("Request failed with status code 500"))
          dispatch(logout())
      })
    console.log(location.pathname.slice(11))
  }, [])


  return (
    <div>
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mt-3">
          <div className="row" style={{ margin: "10px" }}>
            <h2>All Courses</h2>
            {AllExamList1.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-6 center" style={{ padding: "0 5px" }}>
                <ExamBlock item={item} examSlug={slugs.start_course} />
              </div>
            ))}
          </div>
          {AllExamListUnavailable.length != 0 &&
            <div className="row" style={{ margin: "10px" }}>
              <h2>Unavailable Exams</h2>
              {AllExamListUnavailable.map((item, index) => (
                <div key={index} className="col-lg-3 col-md-4 col-6 center" style={{ padding: "0 5px" }}>
                  <ExamBlock item={item} examSlug={slugs.start_course} />
                </div>
              ))}
            </div>
          }

          {pagesLength !== "1" &&
            <div className="" style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
              {myarray}
            </div>
          }
        </div>
      </div>

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}

const ExamBlock = ({ item, examSlug }) => {
  const location = useLocation();
  return (
    <div style={{
      padding: "5px", margin: "5px 0px", border: "1.5px solid #bbb",
      borderRadius: "5px",
    }}>
      {item.meta.featured_image !== "" ?
        <img className="col-12" src={item.meta.featured_image}
          style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
        :
        <img className="col-12"
          src={ExamBlockPlaceHolder}
          style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
      }
      <br />
      <div>
        {item.title.rendered}&nbsp;
      </div>
      <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
        <Link className="sign-in-button-1 col-8 Link"
          to={`${examSlug}/${item.slug}`}
        >
          <div style={{ color: "#fff" }}>
            Open Course
          </div>
        </Link>
      </div>
    </div >
  )

}