import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
import { useSelector } from "react-redux";
const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.id,
    sortable: true,
    reorder: true,
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true
  },
  {
    id: 32,
    name: "Categories",
    selector: (row) => row.meta.category_name,
    sortable: true,
    reorder: true
  },
  {
    id: 3,
    name: "View",
    right: true,
    cell: (row) => <Link to={`${slugs.institute_books_details}${row.slug}`}>View</Link>,

  }
];

export default function AllBooksData() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_book)
      .then((res) => {
        setProjects1(res.data)
        setCustomersList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  const [choose, setChoose] = React.useState(0)
  const [CategoriesList, setCategoriesList] = React.useState([])
  const Login = useSelector((state) => state.LoginState);
  const GetAllCategories = () => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth({
      per_page: 100,
      _fields: "id,title,slug,meta"
    }, slugs.db_slug_category)
      .then((res) => {
        setCategoriesList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAllCategories()
    GetAllProjects({
      per_page: 10000,
      _fields: "id,title,slug,meta",
      "filter[meta_query][1][key]": "batch_institute_id",
      "filter[meta_query][1][value][0]": Login.userID,
      "filter[meta_query][1][compare]": "=",
      "filter[meta_query][relation]": "AND",
    })

  }, [])

  const columnsCategories = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      width: "100px"
    },
    {
      id: 31,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true,
    },
    {
      id: 342,
      name: "Total Class",
      selector: (row) => Projects1.filter(a => a.meta.category_id == row.id).length,
      sortable: true,
      reorder: true
    },
    {
      id: 3,
      name: "Choose",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          setCustomersList(Projects1.filter(a => a.meta.category_id == row.id))
          setChoose(0)
        }}
      >
        Choose
      </div>,
    }
  ];

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Books</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Books
        </div>
      </div>
      {/* <a href={slugs.new_institute_daily_books}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {

          }}
        >Add New Book</div>
      </a> */}
      <div className="row">
        <div className="col-md-6">
          <div style={{ display: "inline", cursor: "pointer" }}>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(0)
                setCustomersList(Projects1)
              }}
            >
              All ({Projects1.length}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(1)
              }}
            >
              Categories ({CategoriesList.length})
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        {choose == 0 &&
          <DataTable
            columns={columns}
            data={CustomersList}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            paginationPerPage={100}
            paginationRowsPerPageOptions={[10, 50, 100, 1000]}
            pagination
            selectableRows
          />
        }
         {choose == 1 &&
          <DataTable
            paginationPerPage={100}
            paginationRowsPerPageOptions={[10, 50, 100, 1000]}
            columns={columnsCategories}
            data={CategoriesList}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            pagination
          />
        }
      </div>
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}