import React, { useEffect } from "react";

export default function Account() {

  return (
    <div>

      <iframe src="https://web.karobarapp.com/"
        style={{
          width: "75vw",
          height: "100vh"
        }}
      />


    </div>
  )
}