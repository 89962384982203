import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import { slugs } from "../../../Constant/slugs";
import { AdsGETApiAuth, NodePOSTApi, NodePOSTLocalApi, NodePUTApi, NodePUTLocalApi } from "../../../Constant/api";
import { useSelector } from "react-redux";
import { CustomButton } from "../../CustomButtom";
import ImageUpload from "../../ImageUpload";

const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.id,
    sortable: true,
    reorder: true,
    compact: true, padding: 0, width: "75px"
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true,
    compact: true, padding: 0
  },
  {
    id: 32,
    name: "Exam Time",
    selector: (row) => row.meta.exam_time,
    sortable: true,
    reorder: true,
    center: true,
    compact: true, padding: 0,
  },
  {
    id: 32,
    name: "Date",
    selector: (row) => row.date.slice(0, 10),
    sortable: true,
    compact: true, padding: 0,
    reorder: true
  },
  {
    id: 34,
    name: "Type",
    selector: (row) => row.meta.exam_type,
    sortable: true,
    compact: true, padding: 0,
    reorder: true
  },
  {
    id: 3,
    name: "View",
    compact: true, padding: 0,
    cell: (row) => <Link to={`${slugs.institute_daily_exam_details}${row.slug}`}>View</Link>,

  }
];

export default function InstituteAllDailyExam() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [ordersList, setOrderList] = React.useState([])
  const [TotalPaid, setTotalPaid] = React.useState(0)
  const [BuyNow, setBuyNow] = React.useState(false)
  const [TotalCount, setTotalCount] = React.useState(0)
  const [TotalOrders, setTotalOrders] = React.useState(0)
  const [Reload, setReload] = React.useState(false)
  const Login = useSelector((state) => state.LoginState);
  const [ExamPrice, setExamPrice] = React.useState("")
  const [VerificationMethod, setVerificationMethod] = React.useState("Esewa")
  const [VerificationID, setVerificationID] = React.useState("")
  const [pid, setPid] = React.useState("")
  const [PhoneNumber, setPhoneNumber] = React.useState(Login.userPhoneNumber)
  const [Email, setEmail] = React.useState(Login.userEmail)
  const instituteID = localStorage.getItem('instituteID');
  const instituteName = localStorage.getItem('instituteName');
  const [OrderID, setOrderID] = React.useState("")
  const [OrderModal, setOrderModal] = React.useState(false)
  const [BatchCount, setBatchCount] = React.useState(0)
  const location = useLocation()

  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_paid_exam)
      .then((res) => {
        setProjects1(res.data)
        setCustomersList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    // const instituteID = localStorage.getItem('userID');
    GetAllProjects({
      per_page: 500,
      _fields: "id,title,slug,date,meta.exam_time,meta.exam_type,meta.batch_name",
      "filter[meta_query][1][key]": "batch_institute_id",
      "filter[meta_query][1][value][0]": Login.userID,
      "filter[meta_query][1][compare]": "=",
      "filter[meta_query][relation]": "AND",
    })
  }, [])
 
  const myform = React.useRef(null)

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Exams</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Exams
        </div>
      </div>
      {/* <div className="row" style={{ padding: "0px 30px" }}>
        <div className="col-md-8 button">
          <a href={slugs.new_institute_daily_exam}>
            <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
              onClick={() => {

              }}
            >Add New Exam</div>
          </a>
        </div>
      </div> */}


      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        <DataTable
          columns={columns}
          data={CustomersList}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          striped
          // sortIcon={<SortIcon />}
          pagination
          selectableRows
        />
      </div>

      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}
